function Intro() {
    return (
        <div className="intro">
            <h3 className="intro1 intro-fade">Hi,</h3>
            <h3 className="intro2 intro-fade">My name is Yuval,</h3>
            <h3 className="intro3 intro-fade">And i'm a full stack developer!</h3>
            <h3 className="intro4 intro-fade">This site is here to show you some of my skills and work.</h3>
            <h3 className="intro5 intro-fade">Enjoy</h3>
        </div>
    );
}

export default Intro;
